import chevron from '@a/icons/chevron.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { PrimaryBtn } from './buttons';
import { Input2, Label, Thanks } from './FormComponents';
import { flexSpace, px } from './layout/styles/classes';

const StyledForm = styled.form`
    ${px};
    padding-top: 32px;
    padding-bottom: 32px;
    position: relative;
    max-width: 540px;

    > h2 {
        margin: 0 0 40px;
    }

    @media (min-width: 768px) {
        max-width: 570px;
    }

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @media (min-width: 1280px) {
        max-width: 630px;
    }
`;

const Flex = styled.div`
    @media (min-width: 540px) {
        ${flexSpace};

        > div {
            width: 100%;

            :first-of-type {
                margin-right: 16px;
            }
        }
    }
`;

export const AppointmentForm = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
    };

    return (
        <StyledForm onSubmit={handleSubmit} id="appointment">
            <h2>Book an Appointment</h2>

            <Flex>
                <div>
                    <Label htmlFor="fname">first name</Label>
                    <Input2 type="text" id="fname" name="firstName" placeholder="First name" />
                </div>
                <div>
                    <Label htmlFor="lname">Last name</Label>
                    <Input2 type="text" id="lname" name="lastName" placeholder="Last name" />
                </div>
            </Flex>
            <Label htmlFor="email">Your Email*</Label>
            <Input2
                type="email"
                id="email"
                name="email"
                required
                placeholder="e.g: email@email.com"
            />
            <Flex>
                <div>
                    <Label htmlFor="phone">Phone Number*</Label>
                    <NumberFormat
                        type="tel"
                        format="+1 (###) ###-####"
                        customInput={Input2}
                        placeholder="123 456-7890"
                        id="phone"
                        name="phone"
                        required
                    />
                </div>
                <div>
                    <Label htmlFor="dateOfBirth">Date of Birth</Label>
                    <Input2
                        id="dateOfBirth"
                        type="date"
                        name="dateOfBirth"
                        css={theme => css`
                            padding: 10px 21px;
                            color: ${theme.colors.lightGray};

                            :focus {
                                color: ${theme.colors.accentDark};
                            }
                        `}
                    />
                </div>
            </Flex>
            <Flex>
                <div>
                    <Label htmlFor="preferredDay">Preferred Day</Label>
                    <Input2
                        id="preferredDay"
                        type="date"
                        name="preferredDay"
                        css={theme => css`
                            padding: 10px 21px;
                            color: ${theme.colors.lightGray};

                            :focus {
                                color: ${theme.colors.accentDark};
                            }
                        `}
                    />
                </div>
                <div>
                    <Label htmlFor="preferredTime">Preferred Time of the Day</Label>
                    <Input2
                        as="select"
                        name="preferredTime"
                        id="preferredTime"
                        placeholder="morning"
                        defaultValue="a"
                        css={theme => css`
                            background: url(${chevron}) no-repeat center right 20px, #f9f9f9;
                            padding: 11px 21px;
                            appearance: none;
                            color: ${theme.colors.lightGray};

                            :focus {
                                color: ${theme.colors.accentDark};
                            }

                            > option[disabled] {
                                display: none;
                            }

                            &::-ms-expand {
                                display: none;
                            }
                        `}
                    >
                        <option value="a" disabled hidden>
                            Choose time
                        </option>
                        <option value="morning">Morning</option>
                        <option value="evening">Evening</option>
                    </Input2>
                </div>
            </Flex>

            <PrimaryBtn
                dark="true"
                css={css`
                    margin-top: 32px;
                `}
            >
                Submit Appointment Request
            </PrimaryBtn>
            <Thanks submit={submit} />
        </StyledForm>
    );
};
